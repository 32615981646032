import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class TraduccionService {
  private traducciones: any = {};
  private listo = false;

  constructor(private http: HttpClient) {}

  cargarIdioma(idioma: string): Promise<void> {
    this.listo = false; // Resetea el estado
    return this.http
      .get(`/assets/i18n/manifest.json`)
      .toPromise()
      .then((manifest: any) => {
        const archivo = manifest[idioma];
        if (!archivo) {
          throw new Error(`No se encontró el archivo para el idioma: ${idioma}`);
        }
        return this.http.get(`/assets/i18n/${archivo}`).toPromise();
      })
      .then((data: any) => {
        this.traducciones = data;
        this.listo = true; // Marca como listo
      });
  }

  traducir(clave: string): string {
    return clave.split('.').reduce((o, i) => (o ? o[i] : null), this.traducciones) || clave;
  }

  get estaListo(): boolean {
    return this.listo;
  }
}
