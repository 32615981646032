import { Component, OnInit, ViewChild, TemplateRef, ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, ValidationErrors, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';

import { ApicallsService } from '../../services/apicalls.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
declare const $;
import { TraduccionService } from '../../services/traduccion.service';
import * as global from '../../services/global.service';
import { ToastrService } from 'ngx-toastr';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { SignaturePad } from 'angular2-signaturepad';
import { GamaCromatica } from 'src/app/models/gama';
import { Estilo } from 'src/app/models/estilo';
import { Zona } from 'src/app/models/zona';

@Component({
    selector: 'app-trabajo-consentimiento-publico',
    templateUrl: './trabajo-consentimiento-publico.component.html',
    styleUrls: ['./trabajo-consentimiento-publico.component.css']
})

export class TrabajoConsentimientoPublicoComponent implements OnInit {
    loader: boolean = true;
    isDarkMode = false;
    newTrabajo = false;
    private modalRef: NgbModalRef | undefined;
    formSign!: UntypedFormGroup;
    formInks!: UntypedFormGroup;
    formNeedles!: UntypedFormGroup;
    tintas:any;
    agujas:any;
    idTrabajo: any;
    redes: any;
    formCliente!: UntypedFormGroup;
    formDNI!: UntypedFormGroup;
    show_anfitrion: boolean = false;
    imageChangedEvent: any = '';
    imageChangedEvent2: any = '';
    originalImageEvent: any = '';
    originalImageEvent2: any = '';
    croppedImage: any = '';
    croppedImage2: any = '';
    hasModal: boolean = false;
    managers: any;
    message!: string;
    cliente:any;
    comunidades:any;
    provincias: any;
    is_spain: boolean = false;
    pasoCliente: boolean = false;
    pasoFirma: boolean = false;
    mostrar_dni: boolean = false;
    imagePath: any;
    tinta_selected: any;
    aguja_selected: any;
    nameFile: any = 'Ningún archivo seleccionado';
    paises:any;
    ciudades:any;
    edad_cliente = 0;
    documentos:any;
    edad_tutor = 0;
    trabajo: any;
    proyectos: any;
    user: any;
    artistas:any;
    global_url: any;
    estudios: any;
    step = 1;
    progress = 25;
    clientes: any;
    zonas: any;
    gamas:any;
    estilos:any;
    today:any;
    dni_frontal:any;
    dni_trasera:any;
    
    videoVisible: boolean = false;

    /*¿Es obligatorio el dni?*/
    dnirequerido: boolean = false;

    dniFrontalSeleccionado = 0;
    dniTraseroSeleccionado = 0;

    showFrontalCamera: boolean = false;
    showTraseraCamera: boolean = false;

    @ViewChild('signaturePad') signaturePad: SignaturePad;
    @ViewChild('canvasFrontal') canvasFrontal: ElementRef;
    @ViewChild('canvasTrasera') canvasTrasera: ElementRef;
    @ViewChild('videoElement') videoElement: ElementRef;

    signaturePadOptions: Object = {
      'minWidth': 0.5,
      'canvasWidth': 400,
      'canvasHeight': 200
    };

    //constructor(private formBuilder: FormBuilder,
    //    private api: ApicallsService,
    //    private toastr: ToastrService,
    //    private currentroute: ActivatedRoute) {
    //}
    constructor(
      private formBuilder: UntypedFormBuilder,
      private api: ApicallsService,
      private toastr: ToastrService,
      private currentroute: ActivatedRoute,
      private traduccionService: TraduccionService,
      private router: Router
    ){}

    getCountryName(countryName: string) {
      return this.traduccionService.traducir(`countries.${countryName}`);
    }
  
    ngOnInit() {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme === 'dark') {
            this.isDarkMode = true;
            document.body.classList.add('dark-mode');
        }
        this.global_url = global.API_URL;
        this.zonas = Zona.get_list();
        this.gamas = GamaCromatica.get_list();
        this.estilos = Estilo.get_list();
        moment.locale('es');
        this.today = moment().format('D MMMM YYYY');
        $.datepicker.regional['es'] = {
          closeText: 'Cerrar',
          prevText: '< Ant',
          nextText: 'Sig >',
          currentText: 'Hoy',
          monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
          dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
          dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
          dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
          weekHeader: 'Sm',
          dateFormat : 'yy-mm-dd',
          firstDay: 1,
          isRTL: false,
          showMonthAfterYear: false,
          yearSuffix: ''
          };
          $.datepicker.setDefaults($.datepicker.regional['es']);
          setTimeout(()=>{                          
           $( '#fecha_nacimiento' ).datepicker(
          {
            onSelect: function( _this, dateText ) 
            {
              var fecha = dateText.selectedYear +'-'+( dateText.selectedMonth + 1 ) +'-'+ dateText.selectedDay;
              this.formCliente.patchValue({'fecha_nacimiento': fecha});
            }.bind( this )
          }
        );
          }, 1000);
        this.formCliente = this.formBuilder.group({
          camposprincipales: [''],
          nombre: ['', Validators.required ],
          estudio_id: ['', Validators.required],
          apellido1: ['', Validators.required],
          apellido2: [''],
          dni: ['', Validators.required],
          sexo: [''],
          fecha_nacimiento: ['', [this.validateDate]],
          imagen: [''],
          changeimage: ['0'],
          email: ['', Validators.required],
          direccion: ['', Validators.required],
          pais_id: ['', Validators.required],
          comunidad_id: ['', Validators.required],
          localidad_id: ['', Validators.required],
          provincia_id: ['', Validators.required],
          cp: ['', Validators.required],
          telefono: ['', Validators.required ],
          extranjero: [0],
          telefono_inter: [''],
          cons_redes: ['1' ],
          cons_email: ['1'],
          cons_notif: ['1'],
          observaciones: [''],
          aviso_medico: [''],
          origen_cliente: [''],
          anfitrion_id: [''],
          tutor_id: [''],
          flexible: [''],
          cliente_oculto: [0]
        });
        this.formDNI = this.formBuilder.group({
          dni_frontal: [''],
          dni_trasera: ['']
        });

        this.currentroute.params.subscribe(params => {
          console.log(params);
          this.idTrabajo = +params['idTrabajo'];
          
          this.traerTrabajo(() => {
            // Llamada para obtener los países después de obtener la información del trabajo
            console.log('Idioma del usuario:', this.trabajo?.firma_telematica_lang);
            this.api.getAllPaises().subscribe((datacat: any) => {
              const paisesResponse = datacat.response;
              if (this.trabajo?.firma_telematica_lang == 'en') {
                // Ordenar los países en inglés
                this.paises = paisesResponse.sort((a, b) => (this.getCountryName(a.nombre) > this.getCountryName(b.nombre) ? 1 : -1));
              } else {
                // Ordenar los países en español
                this.paises = paisesResponse.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
              }
            });
        
            this.api.getAllComunidades().subscribe((datacat: any) => {
              this.comunidades = datacat.response;
            });
        
            this.formSign = this.formBuilder.group({
              firma_cliente: ['', Validators.required],
            });
        
            this.loader = false;
          });

          const idCliente = +params['idCliente'];
          const idTrabajo = +params['idTrabajo'];
          // Ahora debes validar que estos IDs correspondan al mismo trabajo
          this.validarIds(idCliente, idTrabajo);

          this.formInks = this.formBuilder.group({
            inks_used: this.formBuilder.array([])
          });

          this.formNeedles = this.formBuilder.group({
            needles_used: this.formBuilder.array([])
          });

        });
    }

    /*Validando si coinciden la ID del cliente con la ID del trabajo en la URL para evitar el IDOR*/
    private validarIds(idCliente: number, idTrabajo: number) {
      this.api.getTrabajo(this.idTrabajo).subscribe((trabajo: any) => {
        if (trabajo.data && trabajo.data.id === idTrabajo && trabajo.data.cliente_id === idCliente) {
            // Los IDs son válidos, puedes continuar
        } else {
            // Los IDs no son válidos, redirigir a denegado
            this.router.navigate(['/denegado']);
        }
      });
    }
    
    
  
    /*Se valida el formato de la fecha de nacimiento pero no es obligatorio añadirla*/
    validateDate(control: AbstractControl): ValidationErrors | null {
      if (!control.value) {
        return null;
      }
      
      const datePattern1 = /^\d{4}-\d{1,2}-\d{1,2}$/;
      const datePattern2 = /^\d{4}-\d{2}-\d{2}$/;
  
      if (datePattern1.test(control.value) || datePattern2.test(control.value)) {
        return null;
      } else {
        return { invalidDate: true };
      }
    }

    /* Función para eliminar espacios y caracteres no numéricos. Además si hay un '+34', elimínalo. */
    onTelefonoInputChange(value: string) {
      const prefijoRegex = /^\+34\s/;
      const match = value.match(prefijoRegex);
      let telefonoNumeros = value.replace(/\D/g, '');
      if (match) {
        telefonoNumeros = telefonoNumeros.slice(2);
      }
      this.formCliente.get('telefono').setValue(telefonoNumeros);
    }
    
    get inksUsed() {
      return this.formInks.get('inks_used') as UntypedFormArray;
    }
    get needlesUsed() {
      return this.formNeedles.get('needles_used') as UntypedFormArray;
    }
    
    setProvinciasPais(pais: string){
      if(pais != '67'){
        this.api.getProvinciaByPais(pais).subscribe((data: any) => {
          this.provincias = data.response
        });
        this.is_spain = false;
        this.formCliente.get('comunidad_id').clearValidators();
        this.formCliente.patchValue({'comunidad_id': ''});
      }else{
        this.is_spain = true;
        this.formCliente.get('comunidad_id').setValidators(Validators.required);
      }
      
    }
    setProvincias(comunidad: string){
      this.api.getProvinciaByComunidad(comunidad).subscribe((data: any) => {
        this.provincias = data.response
      });
    }

    setCiudades(provincia: string){
      this.api.getCiudadesByProvincia(provincia).subscribe((data: any) => {
        this.ciudades = data.response;
      });
    }

    scrollToVideo() {
      this.videoElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }

    async openCamera(type: string, event: Event) {
      event.stopPropagation();
      this.showFrontalCamera = type === 'frontal';
      this.showTraseraCamera = type === 'trasera';
    
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' }
        });
        this.videoElement.nativeElement.srcObject = stream;
        this.videoVisible = true;
        this.scrollToVideo();
        this.videoElement.nativeElement.play();
    
        // Esperar a que el video esté listo para obtener sus dimensiones
        await new Promise((resolve) => this.videoElement.nativeElement.onloadedmetadata = resolve);
    
        // Esperar a que el usuario tome la foto
        await new Promise((resolve) => this.videoElement.nativeElement.onclick = resolve);
    
        const canvas = type === 'frontal' ? this.canvasFrontal.nativeElement : this.canvasTrasera.nativeElement;
        const ctx = canvas.getContext('2d');
    
        // Ajustar las dimensiones del canvas a 640x480
        canvas.width = 640;
        canvas.height = 480;
    
        let videoWidth = this.videoElement.nativeElement.videoWidth;
        let videoHeight = this.videoElement.nativeElement.videoHeight;
        let scale = Math.max(canvas.width / videoWidth, canvas.height / videoHeight);
        let scaledWidth = videoWidth * scale;
        let scaledHeight = videoHeight * scale;
        let offsetX = (canvas.width - scaledWidth) / 2;
        let offsetY = (canvas.height - scaledHeight) / 2;
    
        ctx.drawImage(this.videoElement.nativeElement, 0, 0, videoWidth, videoHeight, offsetX, offsetY, scaledWidth, scaledHeight);
    
        const imageUrl = canvas.toDataURL('image/jpeg');
    
        if (type === 'frontal') {
          this.formDNI.patchValue({'dni_frontal': imageUrl});
          this.dniFrontalSeleccionado = 1; // Actualizar el indicador
        } else {
          this.formDNI.patchValue({'dni_trasera': imageUrl});
          this.dniTraseroSeleccionado = 1; // Actualizar el indicador
        }
    
        stream.getTracks().forEach(track => track.stop());
        this.videoVisible = false;
        this.showFrontalCamera = false;
        this.showTraseraCamera = false;
    
      } catch (error) {
        console.error('Error al abrir la cámara:', error);
      }
    }
  
  
  
  

    resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<string> {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = event => {
              const img = new Image();
              img.src = event.target.result as string;
              img.onload = () => {
                  const canvas = document.createElement('canvas');
                  let width = img.width;
                  let height = img.height;
  
                  if (width > height) {
                      if (width > maxWidth) {
                          height *= maxWidth / width;
                          width = maxWidth;
                      }
                  } else {
                      if (height > maxHeight) {
                          width *= maxHeight / height;
                          height = maxHeight;
                      }
                  }
                  canvas.width = width;
                  canvas.height = height;
                  const ctx = canvas.getContext('2d');
                  ctx.drawImage(img, 0, 0, width, height);
                  resolve(canvas.toDataURL('image/jpeg', 0.2));
              };
              img.onerror = error => reject(error);
          };
          reader.onerror = error => reject(error);
      });
    }
  

    /* Mostrando avisos si las imágenes subidas del DNI superan los 4MB */
    async fileChangeEvent(event: any, fileInput: HTMLInputElement): Promise<void> {
      const files = event.target.files;
      const fileSize = files[0].size / 1024 / 1024; // Obtener el tamaño del archivo en MB
      const fileType = files[0].type;
    
      if (fileType === 'image/heic') {
        this.toastr.warning('La imagen que intentas subir es formato .HEIC, y no está soportado. Por favor, sube una imagen .JPEG', 'Formato no soportado');
        fileInput.value = '';
        return; // Terminar la ejecución de la función
      }
    
      if (fileSize > 30) {
        this.toastr.warning('El archivo seleccionado supera los 30MB de peso.', 'Tamaño de archivo');
        fileInput.value = '';
      } else {
        try {
          const resizedImage = await this.resizeImage(files[0], 800, 800);
          this.formDNI.patchValue({'dni_frontal': resizedImage});
          this.dniFrontalSeleccionado = 1; // Actualizar el indicador
          console.log('Imagen frontal cargada:', this.formDNI.value.dni_frontal); // Verificar la imagen cargada
          //Para la selección de imágenes desde el dispositivo
          const canvas = this.canvasFrontal.nativeElement;
          const ctx = canvas.getContext('2d');
          const img = new Image();
          img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
          };
          img.src = this.formDNI.value.dni_frontal;
          //Fin de para la selección de imágenes desde el dispositivo

        } catch (error) {
          this.toastr.error('Error al redimensionar la imagen.', 'Error');
        }
      }
    }
    
    async fileChangeEvent2(event: any, fileInput: HTMLInputElement): Promise<void> {
      const files = event.target.files;
      const fileSize = files[0].size / 1024 / 1024; // Obtener el tamaño del archivo en MB
      const fileType = files[0].type;
    
      if (fileType === 'image/heic') {
        this.toastr.warning('La imagen que intentas subir es formato .HEIC, y no está soportado. Por favor, sube una imagen .JPEG', 'Formato no soportado');
        fileInput.value = '';
        return; // Terminar la ejecución de la función
      }
    
      if (fileSize > 30) {
        this.toastr.warning('El archivo seleccionado supera los 30MB de peso.', 'Tamaño de archivo');
        fileInput.value = '';
      } else {
        try {
          const resizedImage = await this.resizeImage(files[0], 800, 800);
          this.formDNI.patchValue({'dni_trasera': resizedImage});
          this.dniTraseroSeleccionado = 1; // Actualizar el indicador
          console.log('Imagen trasera cargada:', this.formDNI.value.dni_trasera); // Verificar la imagen cargada
          //Para la selección de imágenes desde el dispositivo
          const canvas = this.canvasTrasera.nativeElement;
          const ctx = canvas.getContext('2d');
          const img = new Image();
          img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
          };
          img.src = this.formDNI.value.dni_trasera;
          //Fin de para la selección de imágenes desde el dispositivo

        } catch (error) {
          this.toastr.error('Error al redimensionar la imagen.', 'Error');
        }
      }
    }
    
    
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.formDNI.patchValue({'dni_frontal': this.croppedImage});
    }
    imageCropped2(event: ImageCroppedEvent) {
      this.croppedImage2 = event.base64;
      this.formDNI.patchValue({'dni_trasera': this.croppedImage2});
    }
    loadImageFailed() {
      this.toastr.error('Ha ocurrido un error al recortar la imagen.', 'Recorte de imágenes.');
    }
    changeMostrarDNI(){
      this.mostrar_dni  = !this.mostrar_dni;
    }
    traerTrabajo(callback: () => void) {
      this.api.getTrabajo(this.idTrabajo).subscribe((data: any) => {
         this.trabajo = data.data; 
         if(this.trabajo.consentimiento){
          window.location.href = 'https://inkoru.com/';
         }
         this.pasoCliente = true;
         this.cliente = this.trabajo.cliente;
         this.formCliente.patchValue(this.cliente);
         this.formDNI.patchValue(this.cliente.id);
         if(this.cliente.pais_id != 67){
          this.setProvinciasPais(this.cliente.pais_id);
          this.is_spain = false;
          //this.formCliente.get('comunidad_id').clearValidators();
          this.formCliente.patchValue({'comunidad_id': ''});
        }else{
          this.is_spain = true;
          //this.formCliente.get('comunidad_id').setValidators(Validators.required);
          if(this.cliente.comunidad_id){
            this.setProvincias(this.cliente.comunidad_id);
          }
        }
        if(this.cliente.provincia_id){
          this.setCiudades(this.cliente.provincia_id);
        }
         this.trabajo.zonas = JSON.parse(this.trabajo.zona_id);
         this.dni_frontal = this.trabajo.cliente.documentos.find((x: any) => x.nombre == 'DNI frontal');
         this.dni_trasera = this.trabajo.cliente.documentos.find((x: any) => x.nombre == 'DNI trasera');

        /*¿Es obligatorio el dni?*/
        if (this.trabajo?.dnirequerido == '1') {
          this.dnirequerido = true;
        }
        
        //Mostrando los países en inglés si el usuario es inglés
        if (this.trabajo?.firma_telematica_lang === 'en') {
          this.traduccionService.cargarIdioma('en'); // Cambia al idioma inglés
        } else {
          this.traduccionService.cargarIdioma('es'); // Español por defecto
        }        

        if(this.trabajo.tintas.length > 0){
          while ( this.inksUsed.length !== 0) {
            this.inksUsed.removeAt(0)
          }
         this.trabajo.tintas.forEach(element => {
           console.log(element);
           this.inksUsed.push(this.formBuilder.group({tinta_id: element.tinta.id, nombre:element.tinta.nombre, color: element.tinta.color, num_lote: element.tinta.num_lote, registro: element.tinta.registro, codigo: element.tinta.codigo, caducidad: element.tinta.caducidad}));
         });
        }
        if(this.trabajo.agujas.length > 0){
          while ( this.needlesUsed.length !== 0) {
            this.needlesUsed.removeAt(0)
          }
         this.trabajo.agujas.forEach(element => {
           console.log(element);
           this.needlesUsed.push(this.formBuilder.group({aguja_id: element.aguja.id, nombre:element.aguja.nombre, marca: element.aguja.marca, modelo: element.aguja.modelo, num_lote: element.aguja.num_lote, fabricacion: element.aguja.fabricacion, caducidad: element.aguja.caducidad}));
         });
        }
        if(this.trabajo.firma_consentimiento_cliente){
            this.formInks.disable();  
            this.formNeedles.disable();  
        }
        this.api.getTintasEstudio(this.trabajo.estudio_id).subscribe( ( data: any ) => {
            this.tintas = data.response;
            console.log(this.tintas);
        });
        this.api.getAgujasEstudio(this.trabajo.estudio_id).subscribe( ( data: any ) => {
          this.agujas = data.response;
          console.log(this.agujas);
        });

        if (callback) {
          callback(); // Llamamos al callback después de obtener la información del trabajo
        }
      
       });
    }
    inputNumerico(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
    firmaCompleta() {
      this.formSign.patchValue({firma_cliente: this.signaturePad.toDataURL('image/png', 0.5)});
    }
    editCliente(formValue) {
      this.loader = true;  // Activar el loader
      console.log(this.formDNI.value);
      console.log('dniFrontalSeleccionado:', this.dniFrontalSeleccionado);
      console.log('dniTraseroSeleccionado:', this.dniTraseroSeleccionado);
    
      // Verificar si el checkbox está marcado y si las imágenes son obligatorias
      if (this.mostrar_dni || this.dnirequerido) {
        if ((!this.dni_frontal && (this.dniFrontalSeleccionado === 0 || !this.formDNI.value.dni_frontal)) ||
            (!this.dni_trasera && (this.dniTraseroSeleccionado === 0 || !this.formDNI.value.dni_trasera))) {
          this.toastr.error('Es necesario subir las dos imágenes del DNI', 'Actualizar cliente');
          this.loader = false;  // Desactivar el loader
          return;
        }
    
        // Guardar el DNI y luego actualizar el cliente
        this.api.saveDniRemote(this.formDNI.value, this.cliente.id).subscribe(data => {
          this.api.editCliente(formValue, this.cliente.id).subscribe(data => {
            this.toastr.success('Cliente actualizado correctamente', 'Actualizar cliente');
            this.pasoCliente = false;
            this.pasoFirma = true;
            this.loader = false;  // Desactivar el loader cuando se ha completado la subida
          }, error => {
            this.toastr.error('No se ha podido actualizar el cliente', 'Actualizar cliente');
            this.loader = false;  // Desactivar el loader
          });
        }, error => {
          this.toastr.error('No se ha podido guardar el dni', 'Actualizar cliente');
          this.loader = false;  // Desactivar el loader
        });
      } else {
        // Si las imágenes no son obligatorias, solo actualizar el cliente
        this.api.editCliente(formValue, this.cliente.id).subscribe(data => {
          this.toastr.success('Cliente actualizado correctamente', 'Actualizar cliente');
          this.pasoCliente = false;
          this.pasoFirma = true;
          this.loader = false;  // Desactivar el loader
        }, error => {
          this.toastr.error('No se ha podido actualizar el cliente', 'Actualizar cliente');
          this.loader = false;  // Desactivar el loader
        });
      }
    }
    
    
    

    addInkRow(){
      if(this.tinta_selected){
        this.inksUsed.push(this.formBuilder.group({tinta_id: this.tinta_selected.id ,nombre:this.tinta_selected.nombre, color: this.tinta_selected.color, num_lote: this.tinta_selected.num_lote, registro: this.tinta_selected.registro, codigo: this.tinta_selected.codigo, caducidad: this.tinta_selected.caducidad}));
        console.log(this.inksUsed.value);
      }else{
        this.inksUsed.push(this.formBuilder.group({nombre:'', color: '', num_lote: '', registro: '', codigo: '', caducidad: '', tinta_id: ''}));
      }
      //console.log(this.inksUsed.value);
    }
    setTintaExist(tinta){
      var tinta_selected = this.tintas.find((x: any) => x.id == tinta);
      this.tinta_selected = tinta_selected;
      console.log(this.tinta_selected); 
    }

    setAgujaExist(aguja){
      var aguja_selected = this.agujas.find((x: any) => x.id == aguja);
      this.aguja_selected = aguja_selected;
      console.log(this.aguja_selected); 
    }

    sign(formValue) {
      formValue.firma_telematica = 1;
      if(this.trabajo.tipo_proyecto == 1 || this.trabajo.fase_cita == 3 || this.trabajo.fase_cita == 4 || this.trabajo.tipo_proyecto == 2 || this.trabajo.tipo_proyecto == 5){
        formValue.tintas = this.inksUsed.value;
        formValue.agujas = this.needlesUsed.value;
      }
      Swal.fire({
        title: 'Firma Consentimiento',
        text: '¿Deseas firmar el consentimiento?',
        showCancelButton: true,
        confirmButtonColor: '#556ee6',
        confirmButtonText: 'Si',
        cancelButtonText: 'No'}
      ).then((result) => {
        if (result.value) {
          this.api.signConsentimiento(formValue, this.trabajo.id).subscribe( data => {
            window.location.href = 'https://inkoru.com/consentimiento-firmado.html';
          }, error => {
            this.toastr.error('Ha ocurrido un error al firmar el consentimiento, por favor contacta con tu estudio', 'Consentimiento');
          }
        );
          
        }
      });
      
    }
}
