import { Component, AfterViewInit, EventEmitter, Output, OnInit } from '@angular/core';
import {
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models/user';
import { ApicallsService } from '../../services/apicalls.service';
import * as global from '../../services/global.service';
import { ToastrService } from 'ngx-toastr';
import { TraduccionService } from '../../services/traduccion.service';
import { Router, Event, NavigationEnd } from '@angular/router';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';

declare let gtag: Function;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public showSearch = false;
  user:any;
  rol: any;
  num_notificaciones = 0;
  artistas:any;
  dias_restantes = 0;
  cita_selected: any;
  citas_finalizadas:any;
  citaNormal = true;
  clienteAusente_notif = false;
  duracionNormal = true;
  precioNormal = true;
  comisionnotifNormal = true;
  clientes:any;
  notif_menores: any;
  global_url:any;
  cita_nota = '';
  cita_hora = '';
  cita_precio = 0;
  cita_comisionnotif = 0;
  cita_formapago = '';
  roleinfo:any;
  estudio:any;
  route: any;
  cancelado: boolean;
  notificaciones:any;
  notas:any;
  trabajos:any;
  mostrarAlerta: boolean = false;
  mensajeAlerta: string = '';
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private api: ApicallsService,
    private toastr: ToastrService,
    private auth: AuthenticationService,
    private http: HttpClient,
    public traduccionService: TraduccionService
  ) {

    this.router.events.subscribe((event: Event) => {
      
      if (event instanceof NavigationEnd) {
        this.route = this.router.url;
        
        if($('#topnav-menu-content').hasClass('show')){
          $('#topnav-menu-content').removeClass('show');
        }
        this.user = this.auth.currentUserValue;
        this.traduccionService.cargarIdioma(this.user.lang).then(() => {
        });
        if(this.user.rol_id > 1){
          if(!this.user.estudio.trial && !this.user.estudio.subscribed){
            if(!this.route.includes('pago')){
              // Guardando en el log el usuario/dispositivo que ha tumbado la sesión
              const deviceInfo = this.getDeviceInfo();
              this.api.logRedirection({
                user_id: this.user.id,
                name: `${this.user.name} ${this.user.apellido1}`,
                rol_id: this.user.rol_id,
                estudio_nombre: this.user.estudio.nombre,
                device_info: deviceInfo
              }).subscribe(() => {
                // Opcional: manejar la respuesta si es necesario
              });
              window.location.replace("/pago");
            }
          }
        }
        
      }
    });

  }

  ngOnInit(){

   
  
    this.user = this.auth.currentUserValue;
    if(this.user.rol_id > 1){
      if(!this.user.estudio.trial && !this.user.estudio.subscribed){
        if(!this.route.includes('pago')){
          // Guardando en el log el usuario/dispositivo que ha tumbado la sesión
          const deviceInfo = this.getDeviceInfo();
          this.api.logRedirection({
            user_id: this.user.id,
            name: `${this.user.name} ${this.user.apellido1}`,
            rol_id: this.user.rol_id,
            estudio_nombre: this.user.estudio.nombre,
            device_info: deviceInfo
          }).subscribe(() => {
            // Opcional: manejar la respuesta si es necesario
          });
          window.location.replace("/pago");
        }
      }else if(this.user.estudio.subscribed && !this.user.estudio.subscribed_baja && this.user.estudio.suscripcion_id_stripe){
        
        // Condición para verificar si el usuario está suscrito y trabajando
        if (this.user.estudio.subscribed && this.user.estudio.suscripcion_id_stripe) {
          // Envía el evento a Google Analytics
          gtag('event', 'cliente_suscrito_trabajando', {
              'estado_suscripcion': 'activa',
              'usuario_id': this.user.id, // o cualquier otro identificador relevante
              'estudio_id': this.user.estudio.id, // o cualquier otro identificador relevante
          });
        }
        if(this.user.estudio.subscribed && !this.user.estudio.subscribed_baja && this.user.estudio.suscripcion_id_stripe && this.user.estudio.suscripcion_id_stripe != 'fake'){
          this.api.checkSubscription(this.user.estudio.suscripcion_id_stripe).subscribe( ( data: any ) => {
            if(data.estado != 'active'){
              this.api.forzarBajaStripe(this.estudio.customer_id, this.estudio.suscripcion_id_stripe, 'Suscripcion no abonada').subscribe((data: any) => {
                this.auth.logout();
              });
            }
          });
        }
      }
    }
    
    this.rol = this.user.rol_id;
    this.global_url = global.API_URL;
    if(this.rol && this.rol != 1){
      this.estudio = this.user.estudio;
      var created = new Date(this.estudio.created_at.replace(/-/g, "/"));
      this.dias_restantes = 7 - moment().diff(moment(created), 'days');

      if(this.dias_restantes < 1){
        this.dias_restantes = 0;
      }
      if(this.rol > 1){
        if(this.estudio.trial && this.dias_restantes < 1){
          this.api.finishTrial(this.estudio.id).subscribe( ( data: any ) => {
            this.logout();
          });
        }else if(this.estudio.subscribed_baja){
          var today = new Date();
          var fecha_baja = moment(this.estudio.fecha_baja).format('YYYY-MM-DD')
          var hoy = moment(today).format('YYYY-MM-DD');
          if(moment(fecha_baja).isSameOrAfter(hoy)){
            this.api.confirmarBaja(this.estudio.id).subscribe( ( data: any ) => {
              this.logout();
            });
          }
        }
        if(this.user.rol_id == 4){
          this.api.getRoleInfo(this.user.id).subscribe((data: any) => {
            this.roleinfo = data.success.user;
           });
        }
        if(this.user.rol_id == 3){
          this.api.getRoleInfo(this.user.id).subscribe((data: any) => {
            this.roleinfo = data.success.user;
           });
        }
      }
     
      this.traerNotificaciones();
      this.checkForInspeccionesAviso();
    }
    
  }

  /*Detectar dispositivo utilizado*/
  getDeviceInfo() {
    const userAgent = navigator.userAgent;
    let device = 'Desconocido';
  
    if (/android/i.test(userAgent)) {
      device = 'Dispositivo móvil (Android)';
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      device = 'Dispositivo móvil (iOS)';
    } else if (/Windows NT/i.test(userAgent)) {
      device = 'Ordenador (Windows)';
    } else if (/Mac OS X/i.test(userAgent)) {
      device = 'Ordenador (Mac)';
    } else if (/Linux/i.test(userAgent)) {
      device = 'Ordenador (Linux)';
    }
    return device;
  }

  /*Comprobar inspecciones cercanas*/
  checkForInspeccionesAviso() {
    this.api.getInspeccionesAviso(this.user.estudio.id).subscribe((avisos: any) => {
      if (avisos.length > 0) {
        const aviso = avisos[0];
        const fechaInspeccion = aviso.fecha_inspeccion;
        const fechaHumana = this.formatearFechaHumana(fechaInspeccion);
        
        this.mensajeAlerta = `Un estudio cercano ha notificado una nueva inspección con fecha de ${fechaHumana}.`;
        this.mostrarAlerta = true;
      }
    });
  }
  formatearFechaHumana(fecha: string): string {
    const fechaMoment = moment(fecha);
    
    if (fechaMoment.isSame(moment(), 'day')) {
      return 'hoy';
    } else if (fechaMoment.isSame(moment().subtract(1, 'days'), 'day')) {
      return 'ayer';
    } else {
      const diasDiferencia = moment().diff(fechaMoment, 'days');
      return `${fechaMoment.format('DD-MM-YYYY')} (hace ${diasDiferencia} días)`;
    }
  }

  marcarAvisosComoLeidos() {
    this.api.marcarAvisosComoLeidos(this.user.estudio.id).subscribe(() => {
      this.mostrarAlerta = false;
    }, error => {
      console.error('Error al marcar los avisos como leídos:', error);
    });
  }

  cerrarAlerta() {
    this.mostrarAlerta = false;
  }
  
  
  
  traerNotificaciones(){
    
    if(!this.user.avatar || this.user.avatar.includes('placeholder')){
      this.num_notificaciones++;
    }
    
    if(this.rol != 1 && !this.router.url.includes('consentimiento-firma')){
      this.api.getTrabajosEstudioNew(this.estudio.id, 1).subscribe( ( data: any ) => {
          this.trabajos = data.response;
          this.getFinalizadas();
          this.api.getNotasEstudio(this.estudio.id).subscribe( ( data: any ) => {
            this.notas = data.response.filter((x: any) => x.user_id != this.user.id);
            this.notas.forEach(element => {
              var find = this.trabajos.find((x: any) => x.id == element.trabajo_id);
              if(find && find.artista){
                element.artista_id = find.artista.user_id;
              }
            });
            if(this.rol == 4){
              this.notas = this.notas.filter((x: any) => x.artista_id == this.user.id);
            }
            this.num_notificaciones = this.num_notificaciones + this.notas.length;
          });
          
      });
    }else{
     /* this.api.getTrabajos().subscribe( ( data: any ) => {
        this.trabajos = data.response;
        this.getFinalizadas();
        this.api.getNotas().subscribe( ( data: any ) => {
          this.notas = data.response.filter((x: any) => x.user_id != this.user.id);
          this.num_notificaciones = this.num_notificaciones + this.notas.length;
        });
      });*/
    }

    
    if(!this.router.url.includes('consentimiento-firma')){
      if(this.user.rol_id == 2 || this.user.rol_id == 3){
        this.api.getNotificaciones(this.estudio.id).subscribe( ( data: any ) => {
          this.notificaciones = data.data;
          
          this.num_notificaciones = this.num_notificaciones + this.notificaciones.length;
        });
      }
  
      if(this.rol != 1){
        this.api.getArtistasEstudio(this.estudio.id).subscribe( ( data: any ) => {
            this.artistas = data.response;
            this.parseArtistas();
        });
      }
      if(this.rol != 1){
        this.api.getClientesEstudioNew(this.estudio.id).subscribe( ( data: any ) => {
          this.clientes = data.response;
          if(this.rol < 4){
            this.notif_menores = this.clientes.filter((x: any) => moment().diff(x.fecha_nacimiento, 'years') < 18 && !x.tutor_id );
          }
          if(this.notif_menores){
            this.num_notificaciones = this.num_notificaciones /*+ this.notif_menores.length*/;
          }  
        });
      }
    }
    
    
  }
  getFinalizadas(){
    if(this.rol < 4){
      this.citas_finalizadas = this.trabajos.filter((x: any) => x.finalizada && !x.finalizada_confirm);
    }
    if(this.citas_finalizadas){
      this.num_notificaciones = this.num_notificaciones + this.citas_finalizadas.length;
    }
  }
  confirmarNotificacion(notificacion){
    this.api.confirmarNotificacion(notificacion).subscribe( data => {
     
      location.reload();
        }, error => {
          this.toastr.error('No se ha confirmado la notificación', 'Notificación');
        }
      );
  }
  confirmarNotificacionCita(cita){
    this.api.confirmarNotificacionCita(cita).subscribe( data => {
      location.reload();
        }, error => {
          this.toastr.error('No se ha confirmado la cita', 'Cita');
        }
      );
  }
  confirmarNotificacionNota(nota){
    this.api.confirmarNotificacionNota(nota.id).subscribe( data => {
      this.router.navigate(['/trabajos/view/', nota.trabajo_id]);
        }, error => {
          this.toastr.error('No se ha confirmado la nota', 'Nota');
        }
      );
  }
  citaSelected(cita){
    this.cita_selected = cita;
    if(this.cita_selected.aproximado){
      this.precioNormal = false;
    }else{
      this.precioNormal = true;
    }
    this.cita_hora = cita.horaf;
    this.cita_precio = cita.precio;
    this.cita_comisionnotif = cita.comision_artista;
    this.cita_formapago = cita.formapago;
    this.clienteAusente_notif = cita.cancelada;
    this.clienteAusente_notif = cita.ausencia;
  }
  finalizarCita(){

    var obj = {
      'nota' : this.cita_nota,
      'horaf' : this.cita_hora,
      'precio' : this.cita_precio,
      'comision_artista' : this.cita_comisionnotif,
      'formapago' : this.cita_formapago,
      'cancelada' : this.clienteAusente_notif,
      'ausencia' : this.clienteAusente_notif,
      'clienteConflictivo': this.clienteAusente_notif ? 1 : 0,
      'motivoConflicto': this.clienteAusente_notif ? 'Ghosting' : ''
    };
    
    this.api.finalizarNotificacionCita(obj, this.cita_selected.id).subscribe( data => {
      this.toastr.success('Cita confirmada correctamente', 'Cita');
      location.reload();
        }, error => {
          this.toastr.error('No se ha confirmado la cita', 'Cita');
        }
      );
  }
  parseArtistas(){
    if(this.rol == 4){
      this.artistas = this.artistas.filter((x: any) => x.user_id == this.user.id);
    }
    this.artistas.forEach(element => {
      if(!element.firma){
        this.num_notificaciones++;
      }
    });
  }
  isHepatitis(artista){
    var find = artista.documentos.find((x: any) => x.nombre == 'Vacuna Hepatitis');
    if(!find){
      return true;
    }else{
      return false;
    }
  }
  isTetanos(artista){
    var find = artista.documentos.find((x: any) => x.nombre == 'Vacuna Tétanos');
    if(!find){
      return true;
    }else{
      return false;
    }
  }
  tiempoTranscurridoCita(cita){
    var now = moment(moment().format('YYYY-MM-DD HH:mm:ss')); //todays date
    var end = moment(cita.fecha + " "+ cita.horaf); // another date

    var duration = moment.duration(now.diff(end));
    var segundos = duration.asSeconds();
    var minutes = duration.asMinutes();
    var horas = duration.asHours();
    var dias = duration.asDays();
    if(segundos < 60){
      return segundos.toFixed(0)+' seg';
    }else if(minutes < 60){
      return minutes.toFixed(0)+' min';
    }else if(horas < 24){
      return horas.toFixed(0)+' h';
    }else{
      return dias.toFixed(0)+' días';
    }

    
  }
  tiempoTranscurridoNota(nota){
    var now = moment(moment().format('YYYY-MM-DD HH:mm:ss')); //todays date
    var end = moment(nota.created_at); // another date
    var duration = moment.duration(now.diff(end));
    var segundos = duration.asSeconds();
    var minutes = duration.asMinutes();
    var horas = duration.asHours();
    var dias = duration.asDays();
    if(segundos < 60){
      return segundos.toFixed(0)+' seg';
    }else if(minutes < 60){
      return minutes.toFixed(0)+' min';
    }else if(horas < 24){
      return horas.toFixed(0)+' h';
    }else{
      return dias.toFixed(0)+' días';
    }

    
  }
  tiempoTranscurridoNotificacion(notificacion){
    var now = moment(moment().format('YYYY-MM-DD HH:mm:ss')); //todays date
    
    var end = moment(notificacion.created_at); // another date
    var duration = moment.duration(now.diff(end));
    var segundos = duration.asSeconds();
    var minutes = duration.asMinutes();
    var horas = duration.asHours();
    var dias = duration.asDays();
    
    if(segundos < 60){
      return segundos.toFixed(0)+' seg';
    }else if(minutes < 60){
      return minutes.toFixed(0)+' min';
    }else if(horas < 24){
      return horas.toFixed(0)+' h';
    }else{
      return dias.toFixed(0)+' días';
    }
    
  }
  parseHora(hora){
    hora = moment(hora,'HH:mm:ss').format('HH:mm');
    return hora;
  }
  parseDate(fecha){
    return moment(fecha, 'YYYY/MM/DD').format('DD/MM/YYYY');
  }
  configuracion(){
    

    this.api.getRoleInfo(this.user.id).subscribe((data: any) => {
      this.roleinfo = data.success.user; 
      switch(this.user.rol_id ) {
        case 3:
          this.router.navigate(['/managers/edit/',this.roleinfo.id]);
          break;
        case 4:
          this.router.navigate(['/artistas/edit/', this.roleinfo.id]);
          break;
        default:
          this.router.navigate(['/configuracion/']);
      }
     });
    
    
  }
  perfil(){
    

    this.api.getRoleInfo(this.user.id).subscribe((data: any) => {
      this.roleinfo = data.success.user; 
      switch(this.user.rol_id ) {
        case 3:
          this.router.navigate(['/managers/view/',this.roleinfo.id]);
          break;
        case 4:
          this.router.navigate(['/artistas/view/', this.roleinfo.id]);
          break;
        default:
          this.router.navigate(['/configuracion/']);
      }
     });
    
    
  }
  logout() {
    this.auth.logout();
  }
  pause() {
   localStorage.setItem('pause','1');
   this.router.navigate(['/pause']);
  }
}
